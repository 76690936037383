























































import { useCompanyRegistrationForm } from '@/composables/store/Management/useCompanyRegistration';
import { defineComponent, ref } from '@vue/composition-api';
import { useFormValidation } from '@/composables/function/Management/useFormValidation';
import { useRouter } from '@/composables/function/Common/useRouter';

export default defineComponent({
    setup() {
        const { companyRegisterRules } = useFormValidation();

        const { companyForm, error, postRegistration, initForm } = useCompanyRegistrationForm();

        const valid = ref(false);

        const router = useRouter();

        const registration = async (): Promise<void> => {
            const result = await postRegistration();
            if (result) {
                await router.push({
                    name: 'TenantCreation',
                    params: { id: `${companyForm.value.company.id}` },
                });
                initForm();
            }
        };

        return {
            companyRegisterRules,
            companyForm,
            error,
            postRegistration,
            valid,
            registration,
        };
    },
});
