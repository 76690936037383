// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useFormValidation = () => {
    const companyRegisterRules = {
        companyName: [
            (v: string) => !!v || '会社名を入力してください。',
            (v: string) => v.length <= 200 || '会社名は200文字以内で入力してください。',
            (v: string) => !!validateCompanyName(v) || '使用できない文字が含まれています。',
        ],
        companyIdentifier: [
            (v: string) => !!v || '会社識別子を入力してください。',
            (v: string) => v.length <= 200 || '会社識別子は200文字以内で入力してください。',
            (v: string) => !!validateCompanyIdentifier(v) || '使用できない文字が含まれています。',
        ],
    };

    const validateCompanyName = (v: string): RegExpMatchArray | null => {
        /** 「&',-.・」以外の記号を制限 */
        // eslint-disable-next-line no-irregular-whitespace
        return v.match(/^[ぁ-んァ-ヶー一-龠a-zA-Zａ-ｚＡ-Ｚ\d０-９&＆'’,，、−\-.．。・　 ]+$/);
    };

    const validateCompanyIdentifier = (v: string): RegExpMatchArray | null => {
        /** 半角英数記号($_のみ)に制限 */
        return v.match(/^[a-zA-Z]+\w*?$/);
    };

    const tenantCreationRules = {
        companyId: [(v: number) => !!v || '会社名を選択してください。'],
        tenantName: [
            (v: string) => !!v || 'テナント名を入力してください。',
            (v: string) => v.length <= 200 || 'テナント名は200文字以内で入力してください。',
        ],
        tenantIdentifier: [
            (v: string) => !!v || 'テナント識別子を入力してください。',
            (v: string) => !!validateTenantIdentifier(v) || '使用できない文字が含まれています。',
        ],
        statsDomainAddressId: [(v: number) => !!v || 'ドメインを選択してください。'],
    };

    const validateTenantIdentifier = (v: string): RegExpMatchArray | null => {
        /** 半角英数記号($_のみ)に制限 */
        return v.match(/^[a-zA-Z]+\w*?$/);
    };

    const analysisPlanCreationRules = {
        name: [
            (v: string) => !!v || 'プラン名を入力してください。',
            (v: string) => v.length <= 100 || 'プラン名は100文字以内で入力してください。',
        ],
        time: [
            (v: string) => !!v || 'プラン定額時間を入力してください。',
            (v: string) => !!validateIntegerNumber(v) || '整数値を入力してください。',
        ],
        additionalFee: [
            (v: string) => !!v || '従量課金単価を入力してください。',
            (v: string) => !!validateFloatNumber(v) || '少数または整数値を入力してください。',
        ],
        memo: [(v: string | null) => validateStringLength(v) || '備考は100文字以内で入力してください。'],
    };

    const validateIntegerNumber = (v: string | null): RegExpMatchArray | null => {
        if (!v) return null;
        //NOTE: 整数値のみ、0埋めは許さない
        return v.match(/^([1-9]\d*|0)$/);
    };

    const validateFloatNumber = (v: string | null): RegExpMatchArray | null => {
        if (!v) return null;
        //NOTE: 整数値、少数OK 0埋めは許さない
        return v.match(/^([1-9]\d*|0)(\.\d+)?$/);
    };

    const validateStringLength = (v: string | null): boolean => {
        if (!v) return true;
        return v.length <= 100;
    };

    const validateDateRange = (startDate: string, endDate: string): boolean => {
        if (!startDate || !endDate) return false;
        return new Date(endDate) >= new Date(startDate);
    };

    return {
        companyRegisterRules,
        tenantCreationRules,
        analysisPlanCreationRules,
        validateDateRange,
    };
};
